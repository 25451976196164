import React, { useState } from "react";
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Forms.css'

// const TextInputForm = ({ placeholder, name, type, suffix_icon, prefix_icon, labelname }) => {
//     return (
//       <>
//         <div className='form-icon'>
//           <Form.Group className="">
//             {prefix_icon && <span className='prefix-icon'>{prefix_icon}</span>}
//             <input 
//               type={type} 
//               placeholder={placeholder}  
//               name={name} 
//               className={`form-cntrl w-100 ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : "" }`} 
//             />
//             {suffix_icon && <span className='suffix-icon'>{suffix_icon}</span>}
//           </Form.Group>   
//         </div>
//       </>
//     );
//   }

const TextInputForm = ({  name, type, suffix_icon, prefix_icon, labelname, value, onChange }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='form-icon'>
        <Form.Group className="">
          {prefix_icon ? <span className='prefix-icon'>{prefix_icon}</span> : ""}
          <input
            type={type}
            name={name}
            className={`form-cntrl w-100 
                      ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : ""}`}
            value={value}
            onChange={onChange}
          />
          {suffix_icon ? <span className='suffix-icon'>{suffix_icon}</span> : ""}
        </Form.Group>
      </div>
    </>
  )
}
const DropDown = ({  optionlist, labelname, modeltitle = "Create" }) => {
  return (
    <>
     <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='w-100 d-flex'>
        <Select
          options={optionlist}
          labelField='title'
          valueField='value'
          isMulti className='w-100 '
        >
        </Select>
      </div>
    </>
  )
}

const DropDownUI = ({ optionlist = [],name, labelname, placeholder, value, onChange }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue
      });

    }
  };
  return (
    <>
      <div className='pb-2 px-3'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='w-100 regular'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='title'
          valueField='value'
          onChange={handleChange}
          multi>
        </Select>
      </div>
    </>
  )
}
const Calender = ({labelname}) => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <DatePicker showIcon selected={startDate}
        onChange={(date) => setStartDate(date)}
        icon="fa fa-calendar" dateFormat='dd/MM/yyyy' className="w-100 form-cntrl" />
    </>

  )
}
export { TextInputForm, DropDown, DropDownUI, Calender }