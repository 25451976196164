import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import {  TableUI } from '../components/Tables'
import Pagnation from '../components/Pagnation';
import { TextInputForm } from '../components/Forms';
import { PageHead, PageTitle } from '../components/PageTitle'
import { FaMagnifyingGlass } from "react-icons/fa6";
import '../components/Forms.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
const UserTablehead = ["s.NO", "CompanyName", "GST Number", "MobileNumber", "Action"]
const ProductHisData = [
    {
        "id": "1",
        "company_name": 'PRABAU',
        "GST_Number": "WE8554WEWE",
        "Mobile_Number": '9876355677',
    }
]
const Company = () => {
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        })
    })
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://client.barbikan.in/orchids/api/src/auth.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        search_text: ''
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const responseData = await response.json();
                setLoading(false);
                if (responseData.head.code === 200) {
                    setUserData(responseData.body.admin_users);
                } else {
                    throw new Error(responseData.head.msg);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData(); // Call fetchData directly in useEffect

    }, []);
    const [formData, setFormData] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const handleSubmit = async () => {
        try {
            const response = await fetch('http://mahavilvam.barbikan.in/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            console.log(formData);
            const responseData = await response.json();

            console.log(responseData);

            if (responseData.status === 200) {
                toast.success('🦄 Successfullu!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "red",
                });
                setTimeout(() => {
                    navigate("/console/user");
                }, 1000)

            }
            else if (responseData.status === 400 && responseData.msg === 'Missing required fields') {
                // Handle the specific error message
                toast.error('Missing required fields!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <div>
            <Container fluid>
                <div className='main my-3'>
                    <Row>
                        <Col lg='7' md='6' xs='12' className='align-self-center'>
                            <PageTitle PageTitle={"Company"} />
                        </Col>
                        <Col lg='5' md='6' xs='12' className='text-end py-3'>
                            <Buttons lable={<>Add New</>} onClick={handleShow} />
                        </Col>
                        <Col lg={12} md={12} xs={12} className='py-2'>
                            <div className='py-2 product-table'>
                                <TableUI headers={UserTablehead} body={ProductHisData} type="company" style={{ 'borderRadius': '5px' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} size='xl' centered scrollable>
                <Modal.Header>
                    <Modal.Title>
                        <PageHead pagehead={<>Company Creation</>} onClick={handleClose} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Company Name'}
                                    labelname={'Company Name'}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'MobileNumber'}
                                    labelname={'MobileNumber'}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'GST Number'}
                                    labelname={'GST Number'}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <TextInputForm
                                    placeholder={'Address'}
                                    labelname={'Address'}
                                ></TextInputForm>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <div>Billing Address</div>
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        placeholder='Billing Address'
                                        labelname={"Billing Address"} />
                                </div>
                            </Col>
                            <Col lg='4' md='6' xs='12' className='py-3'>
                                <div>Shipping Address</div>
                                <div className='w-100'>
                                    <textarea className='form-cntrl w-100'
                                        placeholder='Shipping Address'
                                        labelname={"Shipping Address"} />
                                </div>
                            </Col>
                            <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                                <div className='text-center'>
                                    {type === "view" ? (
                                        <ClickButton label={<>back</>} onClick={() => navigate("/console/user")}></ClickButton>
                                    ) : (
                                        <>
                                            {type === "edit" ? (
                                                <>
                                                    <ToastContainer
                                                        position="top-center"
                                                        autoClose={2000}
                                                        hideProgressBar={false}
                                                        newestOnTop={false}
                                                        closeOnClick
                                                        rtl={false}
                                                        pauseOnFocusLoss
                                                        draggable
                                                        pauseOnHover
                                                        theme="colored"
                                                    />
                                                    <span className='mx-2'>
                                                        <ClickButton label={<>Update</>} onClick={handleSubmit}></ClickButton>

                                                    </span>

                                                    <span className='mx-2'>
                                                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                                                    </span>
                                                </>
                                            ) : (
                                                <div className='d-flex'>
                                                    <span className='mx-2'>
                                                        <ClickButton label={<> Submit</>} onClick={handleSubmit} disabled={loading}></ClickButton>
                                                        <ToastContainer
                                                            position="top-center"
                                                            autoClose={2000}
                                                            hideProgressBar={false}
                                                            newestOnTop={false}
                                                            closeOnClick
                                                            rtl={false}
                                                            pauseOnFocusLoss
                                                            draggable
                                                            pauseOnHover
                                                            theme="colored"
                                                        />
                                                    </span>
                                                    <span className='mx-2'>
                                                        <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Company