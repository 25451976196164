import React from 'react'
import { Container,Row,Col, Table } from 'react-bootstrap'
import { Calender, DropDownUI, TextInputForm } from '../components/Forms'
import { PageTitle } from '../components/PageTitle'

const Reports = () => {
  return (
    <>
        <div className='main my-2'>
            <Container>
                    <Row>
                        <Col lg="12">
                            <PageTitle PageTitle={<>Reports</>}/>
                        </Col>
                      <Col lg='2' className='py-3'>
                            <DropDownUI labelname={<>Loan Type</>}/>
                      </Col>
                      <Col lg='2' className='py-3'>
                            <DropDownUI labelname={<>Area Name</>}/>
                      </Col>
                      <Col lg='4' className='py-3'>
                        <TextInputForm labelname={<>Customer Name</>}/>
                      </Col>
                      <Col lg='2' className='py-3'>
                        <Calender labelname={<>From</>}/>
                      </Col>
                      <Col lg='2' className='py-3'>
                        <Calender labelname={<>To</>}/>
                      </Col>
                      <Col lg="12">
                      <div className='wire-table'>
                        <Table>
                            <thead>
                                <tr> 
                                    <td>Name</td>
                                    <td>Loan Type</td>
                                    <td>2022-02-02</td>
                                    <td>2022-02-03</td>
                                    <td>2022-02-04</td>      
                                    <td>2022-02-05</td>
                                    <td>2022-02-06</td>
                                    <td>2022-02-07</td>
                                    <td>2022-02-08</td>
                                    <td>2022-02-09</td>
                                    <td>2022-02-10</td>
                               </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td> Mohan</td>
                                    <td>Daily</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                </tr>
                                <tr>
                                    <td> Mohan</td>
                                    <td>Weekly</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                </tr>
                                <tr>
                                    <td> Mohan</td>
                                    <td>Monthly</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                </tr>
                                <tr>
                                    <td> Mohan</td>
                                    <td>Month Int</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                    <td>₹ 1000</td>
                                </tr>
                            </tbody>
                        </Table>
                      </div>
                       
                      </Col>
                    </Row>
            </Container>
        </div>
    </>
  )
}

export default Reports