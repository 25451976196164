import React, { useState } from 'react'
import './SideBar.css'
import { NavLink, Route, Routes } from 'react-router-dom';
import {  MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineHome,MdOutlinePerson, MdBusiness} from "react-icons/md";
import { GiPayMoney, GiReceiveMoney, } from "react-icons/gi";
import { TbReportAnalytics } from "react-icons/tb";
import { PiUsers } from "react-icons/pi";
import { LuDot } from "react-icons/lu"
import { Collapse } from 'react-bootstrap';
import { Header } from './Header';
import Dashboard from '../pages/Dashboard';
import Area from '../pages/Area';
import Customer from '../pages/Customer'
import User from '../pages/User';
import Company from '../pages/Company';
import Loans from '../pages/Loans';
import LoansCreate from '../pages/LoansCreate';
import Interst from '../pages/Interst'
import Reports from '../pages/Reports';
const SideBar = () => {
  const [open, setOpen] = useState(null);
  return (
    <div className=''>
      <Header />
      <aside id='side-bar' className='side-bar'>
        <div className='list-group'>
          <ul>
            <li>
              <NavLink to="/dashboard" >
                <span className="list-icon"><MdOutlineHome /></span>
                <span class="list-text">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/user" >
                <span className="list-icon"><MdOutlinePerson /></span>
                <span class="list-text">User & Access</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/company" >
                <span className="list-icon"><MdBusiness /></span>
                <span class="list-text">Company</span>
              </NavLink>
            </li>
            <li>
              <div className="sub-menu nav-link" onClick={() => setOpen(open === 0 ? null : 0)}>
                <span className="list-icon"><PiUsers /></span>
                <span class="list-text">Master</span>
                <span  className={`list-icon  arrow ${open === 0 ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={open === 0 ? true : false}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to="/console/master/area" className="nav-link" >
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Area</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/console/master/customer" className="nav-link" >
                      <span className="list-icon"><LuDot /></span>
                      <span class="list-text">Customer</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to="/console/loan" >
                <span className="list-icon"><GiPayMoney /></span>
                <span class="list-text">Loan</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/interst" >
                <span className="list-icon"><GiReceiveMoney /></span>
                <span class="list-text">Interst</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/console/reports" >
                <span className="list-icon"><TbReportAnalytics /></span>
                <span class="list-text">Report</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </aside>
      <div id='main'>
        <Routes>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/console/user' element={<User />} ></Route>
          <Route path='/console/company' element={<Company />}></Route>
          <Route path='/console/master/area' element={<Area />}></Route>
          <Route path='/console/master/customer' element={<Customer />}></Route>
          <Route path='/console/loan' element={<Loans />}></Route>
          <Route path='/console/loan/create' element={<LoansCreate />}></Route>
          <Route path='/console/interst' element={<Interst />}></Route>
          <Route path='/console/reports' element={<Reports />}></Route>
        </Routes>
      </div>

    </div>
  )
}

export default SideBar