import React from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { PageTitle } from '../components/PageTitle'
import Select from 'react-select'
import { Tables } from '../components/Tables'
const options = [
  { value: 'This Month', label: 'This Month' },
  { value: 'Last Month', label: 'Last Month' },
  { value: 'This Quarter', label: 'This Quarter' }
]
const ProductTablehead = ["Product Name", 'Quantity']
const ProductData = [{ values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }, { values: ["Oil", "25"] }]
const Dashboard = () => {
  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            <PageTitle PageTitle={"Dashboard"} />
          </Col>
        </Row>
      </Container>
      <div className='px-lg-0'>
        <Container fluid >
          <Row>
            <Col lg='12' className='py-3'>
              <div className='main'>
                <Row>
                  <Col lg='10' className='py-3'>
                    <div className='fs-4'> Loan</div>
                  </Col>
                  <Col lg='2' className='py-3'>
                    <div className='ms-auto w-100'>  <Select options={options} /></div>
                  </Col>
                  <Col lg='6' className='py-3'>
                    <div>This Month Sale </div>
                    <div className='fs-4'> ₹<span>1000</span></div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='12' className='py-3'>
              <div className='main'>
                <Row>
                  <Col lg='10' className='py-3'>
                    <div className='fs-4'>Spent Amount</div>
                  </Col>
                  <Col lg='2' className='py-3'>
                    <div className='ms-auto w-100'>  <Select options={options} /></div>
                  </Col>
                  <Col lg='6' className='py-3'>
                    <div>This Month Sale </div>
                    <div className='fs-4'> ₹<span>1000</span></div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg='' className='py-3'>
              <div className='main'>
                <Row>
                  <Col lg='10' className='py-3'>
                    <div className='fs-4'>Interst </div>
                  </Col>
                  <Col lg='2' className='py-3'>
                    <div className='ms-auto w-100'>  <Select options={options} /></div>
                  </Col>
                  <Col lg='6' className='py-3'>
                    <div>This Month Sale </div>
                    <div className='fs-4'> ₹<span>1000</span></div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}

export default Dashboard