import React, { useState, useEffect } from 'react'
import { Container, Row, Col,Modal, Table } from 'react-bootstrap';
import { Buttons } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle';
import {Calender, DropDownUI, TextInputForm } from '../components/Forms';
import { useLocation } from 'react-router-dom';
const DummyTablehead = ["Name", 'Area',"Amount", "Actions"]
const DummyHisData = [
  {
    "name": 'Bharathi',
    "area": "Mettupatti",
    "intrest_amount":"1500"
  },
  {
    "name": 'Sasi',
    "area":"Padandhal",
    "intrest_amount":"1500"
  }
]
const Interst = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className='main my-2'>
      <Container fluid>
        <Row>
          <Col lg='12'className='py-1 align-self-center'>
            <PageTitle PageTitle={<>Interst</>}/>
          </Col>
          <Col lg="3">
            <TextInputForm labelname={ "Search Customer"}/>
          </Col>
          <Col lg="9" className='py-1 align-self-center'>
              <div className='mx-2 mt-4 pt-2 text-lg-end text-center'> <Buttons lable={<> Add New</>} onClick={handleShow} /></div>
          </Col>
          <Col lg='12'>
            <div className='py-2 c-table'>
              <TableUI headers={DummyTablehead} body={DummyHisData} type="interst"/>
            </div>
          </Col>
        </Row>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          size='xl'
          scrollable={true}
          backdrop="static"
        >
          <Modal.Header>
            <PageHead pagehead={<>Intrest Details</>} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg='4'>
                  <Calender labelname={"Date"}/>
                </Col>
                <Col lg="4" className='py-1 align-self-center'>
                  <div className='mx-2 '> 
                    <DropDownUI  labelname={"Loan Type"}/>
                  </div>
                </Col>
                <Col lg="4" className='py-1 align-self-center'>
                  <div className='mx-2 '> 
                    <DropDownUI  labelname={" Select Area"}/>
                  </div>
                </Col>
                <Col lg="4" className='py-3'>
                  <DropDownUI labelname={"Customer Name"}/>
                </Col>
                <Col lg="4">
                  <TextInputForm labelname={"Amount"}/>
                </Col>
                <Col lg="4">
                  <TextInputForm labelname={"Days"}/>
                </Col>
                <Col lg="4">
                  <TextInputForm labelname={"Balance"}/>
                </Col>
                <Col lg="4">
                  <DropDownUI labelname={"Payment Status"}/>
                </Col>
                <Col lg='7' className='mx-auto'>
                  <div >
                    <Table>
                      <thead>
                        <tr>
                          <th>Due No</th>
                          <th>Interst Amount</th>
                          <th className='text-end'>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                         <tr>
                          <td>83</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>82</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>81</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>80</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>79</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>78</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                        <tr>
                          <td>77</td>
                          <td className='w-25'> <TextInputForm placeholder="Interst Amount"/></td>
                          <td className="text-end"> <Buttons lable={<> Paid</>}/></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Buttons lable={<>Close</>} onClick={handleClose} />
            <Buttons lable={<>Save & New</>} onClick={handleClose} />
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default Interst