import React from 'react'
import Container from 'react-bootstrap/Container';
import{Button,Navbar} from 'react-bootstrap';
import {  MdOutlinePerson, } from "react-icons/md";
import './Header.css'
const Header = () => {
    const handleToggle=()=>{
        document.body.classList.toggle('toggle-sidebar')
    }
  return (
    <div>
    <Navbar className='navfix navbg header fixed-top'>
        <Container fluid>
        <div className='align-self-center'>
            <Button className="menu-toggle"  id="menu-toggle" onClick={handleToggle}>
                <span class="navbar-toggler-icon"></span>
              </Button>
          </div>
        <div className='user-logo mx-5'>
            <img src={require('../assests/images/weddingring.png')} className='img-fluid logo' alt="" />
          </div>    
          <div className='ms-auto user-id d-none d-lg-block pe-5 me-5'>
            <MdOutlinePerson />
          </div>
        </Container>
      </Navbar>
    </div>
  )
}
const Footer =()=>{
  return (
    <div >
      <Navbar className='navbg header fixed-bottom'>
          <Container fluid className='px-5'>
              <div className='ms-auto user-id d-none d-lg-block'>
                <MdOutlinePerson />
              </div>
          </Container>
        </Navbar>
    </div>
  )
}
export {Header ,Footer} 