import React, { useState } from 'react'
import { Container, Row, Col,Modal } from 'react-bootstrap';
import { Buttons, } from '../components/Buttons';
import {  TableUI } from '../components/Tables'
import {  PageHead, PageTitle } from '../components/PageTitle';
import { Calender, DropDownUI, TextInputForm } from '../components/Forms';
import { useLocation,useNavigate } from 'react-router-dom';
const DummyTablehead = ["Loan Type","Name", "Area", 'Amount',"Status"]
const DummyHisData = [
  {
    "loantype": "Daily",
    "name": 'Bharathi',
    "area": "Mettupatti",
    "amount": "15000",
  },
  {
    "loantype": "Monthly",
    "name": 'Sasi',
    "area":"Padandhal",
     "amount": "15000",
  }
]

const Loans = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { type, rowData } = location.state || {};
  return (
    <div className='main my-2'>
      <Container fluid>
        <Row>
        <Col lg='12'className='py-1 align-self-center'>
          <PageTitle PageTitle={<>Loans</>}/>
        </Col>
        <Col lg="3">
          <TextInputForm labelname={ "Search Customer"}/>
        </Col>
          <Col lg="9" className='py-1 align-self-center'>
            <div className='text-end py-2 d-flex justify-content-end'>
               <div className='mx-2'><Buttons lable={<> Exist Customer Loan</>}  onClick={handleShow} /></div>
               <div className='mx-2'><Buttons lable={<> New Loan</>} onClick={() => navigate("/console/loan/create")} /></div>
            </div>
          </Col>
          <Col lg='12'>
            <div className='py-2 c-table'>
              <TableUI headers={DummyTablehead} body={DummyHisData} type="loans"  />
            </div>
          </Col>
        </Row>
      </Container>
      <>
      <Modal show={show} onHide={handleClose} centered size='xl'>
        <Modal.Header closeButton>
          <PageHead pagehead={"Customer Loan"} onClick={handleClose}/>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col lg="4" className='py-3 align-self-center'>
                <div>
                  <Calender labelname={"Date"}/>
                </div>
              </Col>
              <Col lg="4" className='py-3 align-self-center'>
                <DropDownUI labelname={"Customer Name"}/>
              </Col>
              <Col lg="4" className='py-3 align-self-center'>
                <DropDownUI labelname={"Area Name"}/>
              </Col>
              <Col lg="4" className='py-3'>
                  <DropDownUI labelname={"Loan Type"}/>
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Amount "}/>
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Per day / Amount "}/>
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Day "}/>
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Extends Days"}/>
                </Col>
                <Col lg='4'>
                  <div className='pb-2'><label>Remarks</label></div>
                  <textarea className='form-control form-cntrl' rows="4" cols="50"></textarea>
                </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer className='text-center'>
            <Buttons lable={"Submit"}/>
            <Buttons lable={"Cancel"} onClick={handleClose}/>
        </Modal.Footer>
      </Modal>
      </>
    </div>
  )
}

export default Loans