import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import { MdOutlineFileUpload } from "react-icons/md";
import { TextInputForm } from '../components/Forms'
const MonthInt = () => {
  return (
    <div>
      <Container>
          <Row>
            <Col lg="3" className='py-3'>  
               <TextInputForm labelname={<>Interst Rate</>}/>
            </Col>
            <Col lg="3" className='py-3'>  
               <TextInputForm labelname={<>Interst Amount</>}/>
            </Col>
            <Col lg='3' className='py-3 text-center'>
              <div> Upload ID Proof</div>
                <label for="imageUpload" class="upload-icon">
                    <MdOutlineFileUpload />
                </label>
                <input type="file" id="imageUpload" accept="image/*" className='d-none'  />
            </Col>
            <Col lg="3" className='py-3'></Col>
            <Col lg="3" className='py-3'>  
               <TextInputForm labelname={<>Assest Value</>}/>
            </Col>
          </Row>
      </Container>
    </div>
  )
}

export default MonthInt