import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './components/Table.css'
import SideBar from './components/SideBar'
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import Company from './pages/Company';
import Area from './pages/Area';
import Customer from './pages/Customer'
import Loans from './pages/Loans';
import LoansCreate from './pages/LoansCreate';
import Interst from './pages/Interst';
import Reports from './pages/Reports';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Navigate to="/dashboard" replace></Navigate>}></Route>
          <Route element={<SideBar />}>
          <Route path='/dashboard' element={<Dashboard />}></Route>
          <Route path='/console/user' element={<User />} ></Route>
          <Route path='/console/company' element={<Company />}></Route>
          <Route path='/console/master/area' element={<Area />}></Route>
          <Route path='/console/master/customer' element={<Customer />}></Route>
          <Route path='/console/loan' element={<Loans />}></Route>
          <Route path='/console/loan/create' element={<LoansCreate />}></Route>
          <Route path='/console/interst' element={<Interst />}></Route>
          <Route path='/console/reports' element={<Reports />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
