import React from 'react'
import { Container, Row, Col ,Tabs,Tab} from 'react-bootstrap';
import { PageHead} from '../components/PageTitle';
import { DropDownUI, TextInputForm } from '../components/Forms';
import { MdOutlineFileUpload } from "react-icons/md";
import { Buttons } from '../components/Buttons';
import {useNavigate } from 'react-router-dom';
import MonthInt from './MonthInt';
const LoansCreate = () => {
    const navigate = useNavigate();
  return (
    <>
        <div className='main my-2'>
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <PageHead pagehead={"New Loan Details"} onClick={() => navigate("/console/loan/")}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Customer Name"}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Mobile No."}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Father / Husband Name "}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <DropDownUI labelname={"Area Name"}/>
                    </Col>
                    <Col lg='12'>
                        <Tabs
                        defaultActiveKey="address"
                        className="mb-3"
                        fill
                        >
                            <Tab eventKey="address" title="Address">
                                <Container>
                                    <Row>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Alter Mobile No. "}/>
                                        </Col>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Work Details "}/>
                                        </Col>
                                        <Col lg='3' className='py-3 text-center'>
                                                <div> Upload ID Proof</div>
                                            <label for="imageUpload" class="upload-icon">
                                            
                                                <MdOutlineFileUpload />
                                            </label>
                                            <input type="file" id="imageUpload" accept="image/*" className='d-none'  />
                                        </Col>
                                        <Col lg='3' className='py-3 align-self-center text-center'>
                                            <div className='upload-img'>
                                                <img src={require('../assests/images/srivaru.jpg')} className='img-fluid' alt="" />
                                            </div>
                                        </Col>
                                        <Col lg='3'>
                                            <div className='pb-2'><label>Address</label></div>
                                            <textarea className='form-control form-cntrl' rows="4" cols="50"></textarea>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="reference" title="Reference Details">
                                <Container>
                                    <Row>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Reference Name "}/>
                                        </Col>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Work Details "}/>
                                        </Col>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Phone "}/>
                                        </Col>
                                        <Col lg='3' className='py-3 align-self-center'>
                                            <TextInputForm labelname={"Place "}/>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className='main py-3'>
            <Container>
                <Row>
                    <Col lg="3" className='py-3'>
                        <DropDownUI labelname={"Loan Type"}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Amount "}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Per day / Amount "}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Day "}/>
                    </Col>
                    <Col lg="3" className='py-3'>
                        <TextInputForm labelname={"Extends Days"}/>
                    </Col>
                    <Col lg='12' className='py-5'>
                        <div className='text-center py-5'>
                            <span className='mx-2'> <Buttons lable={<>Submit</>}/></span>
                            <span className='mx-2'> <Buttons lable={<>Cancel</>}/></span>
                        </div>
                    </Col>
                </Row>
            </Container>
            <MonthInt/>
        </div>
    </>
  
  
  )
}

export default LoansCreate