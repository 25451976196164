import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { TextInputForm } from '../components/Forms';
import { PageHead, PageTitle } from '../components/PageTitle'
import { FaMagnifyingGlass } from "react-icons/fa6";
import { VscEyeClosed, VscEye } from 'react-icons/vsc';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
const userHead = ["s.NO", "Name", "MobileNumber", "Action"]
const userData = [
    { values: ["1", "KANNAN",  "9865323265",] },
    { values: ["2", "PRABA",  "s9865323265",] },
    { values: ["3", "BHARATHI", "9865323265",] }]
// const ProductHisData = [
//     {
//         "id": "1",
//         "Name": "KANNAN",
//         "RoleSelection": "ADMIN",
//         "Mobile_Number": "999493483"
//     }
// ]
const User = () => {
    const location = useLocation();
    const { type, rowData } = location.state || {};
    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
        location.state = [];
    };
    const handleShow = () => setShow(true);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        type === 'edit' && handleShow();
        setTimeout(() => {
            setLoading(false)
        },1000)
    })
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    };
    const [formData, setFormData] = useState({
        Name: '',
        Mobile_Number: '',
        User_Name: '',
        Password: ''
    });
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://client.barbikan.in/orchids/api/src/auth.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        search_text: ''
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const responseData = await response.json();
                setLoading(false);
                if (responseData.head.code === 200) {
                    setUserData(responseData.body.admin_users);
                } else {
                    throw new Error(responseData.head.msg);
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching data:', error.message);
            }
        };

        fetchData(); // Call fetchData directly in useEffect

    }, []);
    const [showAlert, setShowAlert] = useState(false);
    const handleSubmit = async () => {
        try {
            const response = await fetch('http://mahavilvam.barbikan.in/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            console.log(formData);
            const responseData = await response.json();

            console.log(responseData);

            if (responseData.status === 200) {
                toast.success('🦄 Successfullu!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    navigate("/console/user");
                }, 1000)

            }
            else if (responseData.status === 400 && responseData.msg === 'Missing required fields') {
                // Handle the specific error message
                toast.error('Missing required fields!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            else {
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
    
            <div>
                <Container fluid>
                    <div className='main my-3'>
                        <Row>
                            <Col lg='7' md='6' xs='12' className='align-self-center'>
                                <PageTitle PageTitle={"User"} />
                            </Col>
                            <Col lg='5' md='6' xs='12' >
                                <div className='text-end py-3'>
                                    <Buttons lable={<>Add New</>} onClick={handleShow} />
                                </div>

                            </Col>
                            <Col lg='3' md='6' xs='12' className='py-1'>
                                <TextInputForm
                                    labelname={"Role"}
                                    prefix_icon={<FaMagnifyingGlass />}> </TextInputForm>
                            </Col>
                            <Col lg={12} md={12} xs={12} className='py-2'>
                                <div className='py-2 product-table'>
                                    <TableUI headers={userHead} body={userData} type="USER" style={{ 'borderRadius': '5px' }} />
                                </div>
                            </Col>  
                        </Row>
                    </div>
                </Container>
                <Modal show={show} onHide={handleClose} size='lg' centered scrollable>
                    <Modal.Header>
                        <Modal.Title>
                            <PageHead pagehead={<>User Creation</>} onClick={handleClose} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container fluid>
                            <Row>
                                <Col lg='4' md='6' xs='12' className='py-3'>
                                    <TextInputForm
                                        labelname={'Name'}
                                        name='Name'
                                        value={formData.Name}
                                        onChange={(e) => handleChange(e, 'Name')}
                                    ></TextInputForm>
                                </Col>
                                <Col lg='4' md='6' xs='12' className='py-3'>
                                    <TextInputForm
                                        labelname={'MobileNumber'}
                                        type={'number'}
                                        name='Mobile_Number'
                                        value={formData.Mobile_Number}
                                        onChange={(e) => handleChange(e, 'Mobile_Number')}
                                    ></TextInputForm>
                                </Col>
                                <Col lg='4' md='6' xs='12' className='py-3'>
                                    <TextInputForm
                                        labelname={'PassWord'}
                                        suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
                                        type={showPassword ? 'text' : 'password'}
                                        name='Password'
                                        value={formData.Password}
                                        onChange={(e) => handleChange(e, 'Password')}
                                    ></TextInputForm>
                                </Col>
                              
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                    <Col lg='12' md='12' xs='12' className='py-2 align-self-center'>
                                    <div className='text-center'>
                                        {type === "edit" ? (
                                            <>
                                                <ToastContainer
                                                    position="top-center"
                                                    autoClose={2000}
                                                    hideProgressBar={false}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover
                                                    theme="colored"
                                                />
                                                <span className='mx-2'>
                                                    <ClickButton label={<>Update</>} onClick={handleSubmit}></ClickButton>

                                                </span>

                                                <span className='mx-2'>
                                                    <ClickButton label={<>Cancel</>} onClick={() => navigate("/console/user")}></ClickButton>
                                                </span>
                                            </>

                                        ) : (
                                            <>
                                                <ToastContainer
                                                    position="top-center"
                                                    autoClose={2000}
                                                    hideProgressBar={false}
                                                    newestOnTop={false}
                                                    closeOnClick
                                                    rtl={false}
                                                    pauseOnFocusLoss
                                                    draggable
                                                    pauseOnHover
                                                    theme="colored"
                                                />
                                                <span className='mx-2'>
                                                    <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                                                </span>

                                                <span className='mx-2'>
                                                    <ClickButton label={<>Cancel</>} onClick={() => { location.state = []; }}></ClickButton>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </Col>
                    </Modal.Footer>
                </Modal>
            </div>
           
        </>
    )
}

export default User