import React, { useState, useEffect } from 'react'
import { Container, Row, Col,  Modal } from 'react-bootstrap';
import { Buttons} from '../components/Buttons';
import { TableUI } from '../components/Tables'
import { PageHead } from '../components/PageTitle';
import {  TextInputForm } from '../components/Forms';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useLocation } from 'react-router-dom';
const ProductTablehead = ["S No", 'Area Name', "Status"]
const DummyHisData = [
  {
    "name": '01',
    "amount": "Sattur",
  },
  {
    "name": '02',
    "amount": "LIC Colony",
  },
  {
    "name": '03',
    "amount": "Irukangudi",
  },
  {
    "name": '04',
    "amount": "Chathirapatti",
  },
  {
    "name": '05',
    "amount": "Mettupatti",
  },
  {
    "name": '06',
    "amount": "Ameer Palayam",
  },
  {
    "name": '07',
    "amount": "Padandhal",
  },
]

const Party = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [Limit, SetLimit] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    })
  })

  const [clickedData, setClickedData] = useState(DummyHisData[0]);

  const handleClick = (data) => {
    setClickedData(data);
  };

  return (
    <div>
      {
        loading ? <PropagateLoader className='text-center pt-5 '
          color={'#d43333'}
          loading={loading}
          size={18} /> :
          <div>
            <Container fluid >
              <Row>
                <Col lg="12" className='py-1'>
                  <div className='main my-2'>
                    <div className='fixed'>
                      <div></div>
                      <div className='text-end py-2'><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
                    </div>
                    <div className='py-2 c-table'>
                      <TableUI headers={ProductTablehead} body={DummyHisData} type="partypurches" style={{ 'borderRadius': '5px' }} onClick={handleClick} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <>
              <Modal
                show={show}
                onHide={handleClose}
                centered
                size='md'
              >
                <Modal.Header>
                  <PageHead pagehead={<> Area</>} onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col lg="12" className='py-3'>
                        <TextInputForm labelname={"Area Name"} />
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Buttons lable={<>Close</>} onClick={handleClose} />
                  <Buttons lable={<>Save & New</>} onClick={handleClose} />
                </Modal.Footer>
              </Modal>
            </>
          </div>
      }
    </div>
  )
}

export default Party