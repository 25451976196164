import React from 'react'
import { Table, Button, Dropdown } from 'react-bootstrap'
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import './Table.css'
const Tables = (props) => {
    return (
        <Table responsive="md">
            <tr className=''>
                {props.headers.map((data) => <th>{data}</th>)}
            </tr>
            {props.body != null ?
                <tbody>
                    {props.body.map((rowData) =>
                        <tr className=''>
                            {rowData.values.map((rowValue) => <td >{rowValue}</td>)}
                        </tr>
                    )}
                </tbody> :
                <tr className='text-center mx-auto'>
                    <td>No Record Found</td>
                </tr>}
        </Table>
    )
}
const ProductTable = (props) => {
    return (
        <Table responsive="md">
            <tr className=''>
                {props.headers.map((data) => <th>{data}</th>)}
            </tr>
            {props.body != null ?
                <tbody>
                    {props.body.map((rowData) =>
                        <tr className=''>
                            {rowData.values.map((rowValue) => <td >{rowValue}</td>)}
                            <td className='text-end'>
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        <Button className='action'><BiDotsVerticalRounded /></Button>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >View</Dropdown.Item>
                                        <Dropdown.Item >Edit</Dropdown.Item>
                                        <Dropdown.Item >Delete</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    )}

                </tbody> :
                <tr className='text-center mx-auto'>
                    <td>No Record Found</td>
                </tr>}
        </Table>
    )
}

const   TableUI = ({ headers, body, style, type, rowData, planViewAction, onClick }) => {
    const navigate = useNavigate();

    const handleEditClick = (rowData) => {
        navigate("/console/user", { state: { type: "edit", rowData: rowData } });
    }
    const handleDeleteClick = async (id) => {
        console.log(id);
        try {
            const response = await fetch('https://client.barbikan.in/orchids/api/src/auth.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    delete_user_id: id
                })
            });

            const responseData = await response.json();

            console.log(responseData);

            if (responseData.head.code === 200) {
                navigate("/console/user");
                window.location.reload();
            } else {
                console.log(responseData.head.msg);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleCompanyViewClick = (rowData) => {
        navigate("/console/company", { state: { type: "view", rowData: rowData } });
    };
    const handleCompanyEditClick = (rowData) => {
        navigate("/console/company", { state: { type: "edit", rowData: rowData } });
    }
    const handleCompanyDeleteClick = async (id) => {
        console.log(id);
        try {
            const response = await fetch('https://client.barbikan.in/orchids/api/src/plan.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    delete_plan_id: id
                })
            });

            const responseData = await response.json();

            console.log(responseData);

            if (responseData.head.code === 200) {
                navigate("/console/plan");
                window.location.reload();
            } else {
                console.log(responseData.head.msg);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleRowClick = (rowData) => {
        onClick(rowData);
    };
    return (
        <Table responsive="md" style={style}>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {body.map((rowData, rowIndex) => (
                    <tr key={rowIndex} >
                        {type === "USER" && (
                            <>
                                <td>{rowData.id}</td>
                                <td>{rowData.Name}</td>
                                <td>{rowData.Mobile_Number}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                        {type === "company" && (
                            <>
                                <td>{rowData.id}</td>
                                <td>{rowData.company_name}</td>
                                <td>{rowData.GST_Number}</td>
                                <td>{rowData.Mobile_Number}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCompanyViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                        {type === "partypurches" && (
                            <> {/* Fragment shorthand */}
                                <td onClick={() => handleRowClick(rowData)}>{rowData.name}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.amount}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCompanyViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                        {type === "partysales" && (
                            <> {/* Fragment shorthand */}
                                <td onClick={() => handleRowClick(rowData)}>{rowData.name}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.mobile_no}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.area}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCompanyViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                        {type === "loans" && (
                            <> {/* Fragment shorthand */}
                                <td onClick={() => handleRowClick(rowData)}>{rowData.loantype}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.name}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.area}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.amount}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCompanyViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                        {type === "interst" && (
                            <> {/* Fragment shorthand */}
                                <td onClick={() => handleRowClick(rowData)}>{rowData.name}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.area}</td>
                                <td onClick={() => handleRowClick(rowData)}>{rowData.intrest_amount}</td>
                                <td className='text-end'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <Button className='action'><BiDotsVerticalRounded /></Button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleCompanyViewClick(rowData)}>View</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyEditClick(rowData)}>Edit</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleCompanyDeleteClick(rowData.id)}>Delete</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </>
                        )}
                      
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};
export { Tables, ProductTable, TableUI } 