import React, { useState, useEffect } from 'react'
import { Container, Row, Col,Modal, Tab, Tabs } from 'react-bootstrap';
import { Buttons, CreditNote, PayIn, PayOut, Sale } from '../components/Buttons';
import { ProductTable, TableUI } from '../components/Tables'
import { PageHead, PageTitle } from '../components/PageTitle';
import {DropDownUI, TextInputForm } from '../components/Forms';
import { useLocation } from 'react-router-dom';
const DummyTablehead = ["Name", 'Phone',"Area", "Status"]
const DummyHisData = [
  {
    "name": 'Bharathi',
    "mobile_no": "9994307256",
    "area": "Mettupatti",
  },
  {
    "name": 'Sasi',
    "mobile_no": "9597537673",
    "area":"Padandhal"
  }
]

const Party = () => {
  const location = useLocation();
  const { type, rowData } = location.state || {};
  const [Limit, SetLimit] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [clickedData, setClickedData] = useState(DummyHisData[0]);
  const handleClick = (data) => {
    setClickedData(data);
  };
  return (
    <div className='main my-2'>
      <Container fluid>
        <Row>
        <Col lg='12'className='py-1 align-self-center'>
          <PageTitle PageTitle={<>Customer</>}/>
        </Col>
        <Col lg="3">
          <TextInputForm labelname={ "Search Customer"}/>
        </Col>
          <Col lg="9" className='py-1 align-self-center'>
            <div className='text-end py-2'><Buttons lable={<> Add New</>} onClick={handleShow} /></div>
          </Col>
          <Col lg='12'>
            <div className='py-2 c-table'>
              <TableUI headers={DummyTablehead} body={DummyHisData} type="partysales" onClick={handleClick} />
            </div>
          </Col>
        </Row>
      </Container>
      <>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          size='xl'
        >
          <Modal.Header>
            <PageHead pagehead={<>Customer Details</>} onClick={handleClose} />
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Customer Name"} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Father / Husband Name"} />
                </Col>
                <Col lg="4" className='py-3'>
                  <TextInputForm labelname={"Phone No."} />
                </Col>
              </Row>
            </Container>
            <Tabs
              defaultActiveKey="address"
              className="mb-3 justify-content-center"
            >
              <Tab eventKey="address" title="Address">
                <Container>
                  <Row>
                    <Col lg="6" className='py-3'>
                      <TextInputForm labelname={"Alter No."} />
                    </Col>
                    <Col lg="6" className='py-3'>
                      <TextInputForm labelname={"Work Details"} />
                    </Col>
                    <Col lg="6" className='py-3'>
                        <DropDownUI labelname={"Area Name"}/>
                    </Col>
                    <Col lg='6' className='py-3'>
                       <label className='pb-2'> Address</label>
                      <textarea 
                       className='form-cntrl form-control' />
                    </Col>
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="credit-balance" title="Reference Details">
                <Container>
                  <Row>
                    <Col lg="4" className='py-3'>
                      <TextInputForm labelname={"Reference Name"} />
                    </Col>
                    <Col lg="4" className='py-3'>
                        <TextInputForm labelname={"Mobile Number"} />
                    </Col>
                    <Col lg="4" className='py-3'>
                        <TextInputForm labelname={"Work Details"} />
                    </Col>
                    <Col lg="6" className='py-3'>
                        <DropDownUI labelname={"Area Name"}/>
                    </Col>
                    <Col lg='6' className='py-3'>
                       <label className='pb-2'> Address</label>
                      <textarea 
                       className='form-cntrl form-control' />
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Buttons lable={<>Close</>} onClick={handleClose} />
            <Buttons lable={<>Save & New</>} onClick={handleClose} />
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default Party